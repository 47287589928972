<template>
  <v-row justify="center">
    <v-dialog v-model="active" persistent max-width="350">
      <v-card class="card primary--text pa-7 text-center">
        <div class="message-title text-16 font-700" v-html="message.title"></div>
        <div
          class="description text-14 font-500"
          style="overflow-wrap: break-word"
          v-html="message.description"
        ></div>
        <v-btn class="btn mt-5 submit-btn primary--bg font-500" @click="handleClick"
          >{{ message.btn }}</v-btn
        >
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "information-modal",
  data() {
    return {};
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    message: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    handleClick() {
      this.$emit("submit");
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
  border-radius: 20px;
}
.description {
  color: #757575;
}
.submit-btn {
  color: white;
}
</style>
