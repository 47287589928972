<template>
  <div class="register">
    <div v-if="onStep === 0" class="d-flex justify-center">
      <div class="register-form-group">
        <div class="mt-10 d-flex justify-center">
          <img :src="phyathaiLogo" width="230px" />
        </div>

        <div class="d-flex justify-center mt-8">
          <v-btn
            elevation="0"
            rounded
            color="#235D3A"
            class="btn-box text-16 white--text py-5"
            @click="showRegisterModal = true"
          >
            ลงทะเบียนแบบมีรหัส
          </v-btn>
        </div>
        <div class="d-flex justify-center mt-8">
          <v-btn
            elevation="0"
            rounded
            color="#CECECE"
            class="btn-box text-16 py-5"
            @click="nextStep()"
            style="color: #757575"
          >
            ลงทะเบียนแบบไม่มีรหัส
          </v-btn>
        </div>
        <div class="mt-6 text-center text-14">
          <div class="mt-5" style="color: #969595">
            หากท่านต้องการรหัสเพื่อสมัครบัตรสมาชิก<br />ของ โรงพยาบาลพญาไท 3
            สามารถติดต่อขอรับได้ที่
          </div>
          <div class="mt-4" style="color: #397d54">
            ฝ่ายประชาสัมพันธ์โรงพยาบาลพญาไท 3<br />หรือติดต่อเจ้าหน้าที่ทาง LINE
            Official Account
            <div class="text-decoration-underline">@phyathai3family</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="onStep > 0 && !showRegisterModal">
      <Registertitle
        :onSteps="onStep"
        :onConsent="consent.isShow"
        :isRegistered="user.general_profile.mobile !== ''"
        @closeTerm="consent.isShow = false"
      />
    </div>
    <div v-if="onStep === 1 && !showRegisterModal">
      <ValidationObserver ref="registerForm">
        <Registerform :profiles="profile" @submit="setProfile" />
      </ValidationObserver>
    </div>
    <div v-if="onStep === 2 && !consent.isShow">
      <TermsForm
        :terms="term"
        :onDomain="onDomain"
        @submit="setTerm"
        @back="backStep"
        @readDetail="readDetail"
      />
    </div>
    <TermsConsent v-if="consent.isShow" :message="consent" />
    <div v-if="onStep === 3">
      <OTPForm
        :refCode="otp.refCode"
        :loading="isLoadingOTP"
        :isInvalidOTP="isInvalidOTP"
        @submit="verifyOTP"
        @resend="resendOTP"
        @back="backStep"
      />
    </div>
    <RegisterWithCodeModal
      :active="showRegisterModal"
      :registerCode="registerCode"
      :isInvalid="isInvalidRegisterCode"
      :onDomain="onDomain"
      @close="closeRegisterWithCode"
      @submit="registerWithCode"
    />
    <RegisterSuccess
      :active="isRegisterSuccess"
      :message="{
        title: 'ลงทะเบียนเรียบร้อย',
        description:
          'ระบบได้ทำการบันทึกข้อมูลของท่านแล้วกรุณารอการตอบรับจากระบบภายใน 72 ชม.',
      }"
      @submit="submitRegister"
    />
    <InformationModal
      :active="isConsentNotSubmitAll"
      :message="{
        title: 'คุณแน่ใจหรือไม่ ?<br/>ที่จะไม่กดยอมรับข้อกำหนดทั้งหมด',
        description: '*เนื่องจากจะส่งผลต่อใช้งานฟังก์ชันบนระบบของท่าน',
        btn: 'กลับไปยอมรับ',
      }"
      @submit="isConsentNotSubmitAll = false"
    />
    <ErrorModal
      :active="isError"
      :message="errorMessage"
      @submit="isError = false"
    />
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

import ErrorModal from "@/components/modal/ErrorModal";

import InformationModal from "@/components/modal/InformationModal.vue";
import RegisterSuccess from "@/components/modal/RegisterSuccess.vue";
import RegisterWithCodeModal from "@/components/modal/RegisterWithCodeModal.vue";
import Registerform from "../components/register/RegisterForm.vue";
import TermsForm from "../components/register/TermsForm.vue";
import Registertitle from "../components/register/RegisterTitle.vue";
import OTPForm from "../components/profile/OTPForm.vue";
import TermsConsent from "../components/TermsConsent.vue";

export default {
  name: "Register",
  components: {
    RegisterWithCodeModal,
    Registerform,
    TermsForm,
    Registertitle,
    OTPForm,
    ErrorModal,
    TermsConsent,
    RegisterSuccess,
    InformationModal,
  },
  data: () => ({
    phyathaiLogo: require("@/assets/img/phyathai3logo.png"),
    showRegisterModal: false,
    onStep: 0,
    isInvalidOTP: false,
    isLoadingOTP: false,
    isError: false,
    isInvalidRegisterCode: false,
    isConsentNotSubmitAll: false,
    isRegisterSuccess: false,
    errorMessage: {},
    consent: {},
    registerCode: "",
    profile: {
      firstName: null,
      lastName: null,
      gender: null,
      birth_date: null,
      phoneNumber: null,
      email: null,
      address: null,
      province: null,
      sub_district: null,
      district: null,
      road: null,
      postcode: null,
    },
    otp: {
      id: null,
      refCode: null,
    },
    term: {
      isVisitPT: false,
      pdpa: false,
      marketing: false,
    },
  }),

  computed: {
    ...mapState("Request", ["requestApi"]),
    ...mapState("Auth", ["user"]),
    ...mapState("Layout", ["onDomain"]),
  },

  methods: {
    ...mapActions({
      setUserProfile: "Auth/setUserProfile",
    }),
    async createUserMember() {
      try {
        this.$wait.start("app loading");
        const general_profile = {
          first_name: this.profile.firstName,
          last_name: this.profile.lastName,
          birth_date: this.toTimestamp(this.profile.birth_date),
          mobile: this.profile.phoneNumber,
          gender: this.profile.gender,
        };
        this.profile.email
          ? (general_profile["email"] = this.profile.email)
          : false;

        const address_profile = {
          address1: this.profile.address,
          road: this.profile.road,
          district: this.profile.district,
          sub_district: this.profile.sub_district,
          province: this.profile.province,
          postcode: this.profile.postcode,
        };

        let hospital_profile = {};
        const consents = [];
        let entries = Object.entries(this.term);
        entries.map(([key, val] = entries) => {
          if (key === "isVisitPT" && this.onDomain === "be") {
            hospital_profile = {
              hospital_number: "",
              previous_pyathai_customer: val,
            };
          } else {
            consents.push({
              id: key,
              name: key,
              content: "",
              accepted: val,
            });
          }
        });
        let consent_profile = {};
        const getContent = async (consent) => {
          const term = await this.requestApi.consent.getConsent({
            id: consent.id,
          });
          return {
            id: consent.id,
            name: consent.id,
            content: term.content,
            accepted: Boolean(consent.accepted),
          };
        };
        const consentPromises = consents.map((consent) => getContent(consent));
        await Promise.allSettled(consentPromises).then((result) => {
          consent_profile = { consents: result.map((e) => e.value) };
        });
        const profile = {
          hospital_profile,
          general_profile,
          address_profile,
          consent_profile,
        };
        const updateUser = await this.requestApi.user.updateUser({
          requestBody: profile,
          otpId: this.otp.id,
        });

        this.setUserProfile({ user: updateUser, isLoggedIn: true });
        if (this.registerCode !== "") {
          try {
            await this.requestApi.membership.renewMembership({
              domain: this.onDomain,
              requestBody: {
                code: this.registerCode,
              },
            });
          } catch {
            throw new Error("invalid register code");
          }
        }
        if (this.onDomain === "be" || this.onDomain === "mom-baby") {
          this.submitRegister();
        } else {
          this.isRegisterSuccess = true;
        }
      } catch (err) {
        if (err.message === "invalid register code") {
          this.isInvalidRegisterCode = true;
          this.showRegisterModal = true;
        } else if (err.status === 400) {
          // console.log('OTP')
          this.sendOTP();
          this.nextStep();
        }
      } finally {
        this.$wait.end("app loading");
      }
    },

    async registerWithCode(code) {
      try {
        this.showRegisterModal = false;
        this.$wait.start("app loading");
        await this.requestApi.membership.verifyMembershipRenewCode({
          domain: this.onDomain,
          code,
        });
        this.registerCode = code;
        this.showRegisterModal = false;
        this.isInvalidRegisterCode = false;

        if (this.onDomain === "be" && !this.user.general_profile.mobile) {
          this.nextStep();
        }
      } catch (err) {
        console.log(err);
        this.showRegisterModal = true;
        this.isInvalidRegisterCode = true;
      } finally {
        this.$wait.end("app loading");
      }
    },
    closeRegisterWithCode() {
      this.showRegisterModal = false;
      if (this.onDomain !== "be" || this.user.general_profile.mobile) {
        this.$router
          .replace({
            path: "/membership",
          })
          .catch(() => {});
      }
    },
    nextStep() {
      if (this.onStep < 4) {
        this.onStep += 1;
      }
    },
    submitRegister() {
      this.isRegisterSuccess = false;
      this.$router
        .replace({
          path: "/membership",
        })
        .catch(() => {});
    },
    async setProfile(profile) {
      const isValid = await this.$refs.registerForm.validate();
      if (!isValid) {
        this.errorMessage = {
          description: "ท่านกรอกข้อมูลไม่ครบถ้วน กรุณาตรวจสอบข้อมูลอีกครั้ง",
          btn: "กลับไปตรวจสอบ",
        };
        this.isError = true;
        return;
      }
      this.profile = profile;
      this.nextStep();
    },
    backStep() {
      this.onStep -= 1;
    },
    async setTerm(e) {
      this.term = e;
      const stack = [];
      let entries = Object.entries(this.term);
      entries.map(([key, val] = entries) => {
        if (key !== "isVisitPT") {
          stack.push(val);
        }
      });
      if (stack.every((e) => e === true)) {
        await this.createUserMember();
      } else {
        this.isConsentNotSubmitAll = true;
      }
    },
    async sendOTP() {
      try {
        this.$wait.start("app loading");
        const otp = await this.requestApi.otp.requestOtp({
          requestBody: { mobile: this.profile.phoneNumber },
        });
        this.otp = {
          id: otp.id,
          refCode: otp.ref_code,
        };
      } catch (err) {
        console.log(err);
      } finally {
        this.$wait.end("app loading");
      }
    },
    async verifyOTP(code) {
      try {
        this.$wait.start("app loading");
        this.isLoadingOTP = true;
        await this.requestApi.otp.verifyOtp({
          id: this.otp.id,
          requestBody: {
            otp: code,
          },
        });
        this.isInvalidOTP = false;
        await this.createUserMember();
      } catch (err) {
        this.isInvalidOTP = true;
        console.log(err);
      } finally {
        this.isLoadingOTP = false;
        this.$wait.end("app loading");
      }
    },
    async resendOTP() {
      try {
        this.$wait.start("app loading");
        const otp = await this.requestApi.otp.requestOtp({
          requestBody: { mobile: this.profile.phoneNumber },
        });
        this.otp = {
          id: otp.id,
          refCode: otp.ref_code,
        };
      } catch (err) {
        console.log(err);
      } finally {
        this.$wait.end("app loading");
      }
    },
    async readDetail(value) {
      try {
        this.$wait.start("app loading");
        const consents = await this.requestApi.consent.getConsent({
          id: value,
        });
        this.consent = {
          isShow: true,
          consents,
        };
      } catch (err) {
        console.log(err);
      } finally {
        this.$wait.end("app loading");
      }
    },
  },
  created() {
    if (this.user.general_profile.mobile !== "" || this.onDomain !== "be") {
      this.showRegisterModal = true;
      this.onStep = 1;
    }
    this.term = {
      isVisitPT: this.user.hospital_profile.previous_pyathai_customer,
      pdpa: this.user.consent_profile.consents.filter((c) => c.id === "pdpa")[0]
        .accepted,
      marketing: this.user.consent_profile.consents.filter(
        (c) => c.id === "marketing"
      )[0].accepted,
    };
    this.term[this.onDomain] = this.user.consent_profile.consents.filter(
      (c) => c.id === this.onDomain
    )[0].accepted;
    this.profile = {
      firstName: this.user.general_profile.first_name,
      lastName: this.user.general_profile.last_name,
      gender: this.user.general_profile.gender,
      birth_date:
        this.user.general_profile.birth_date === 0
          ? null
          : this.toDateDOB(this.user.general_profile.birth_date),
      phoneNumber: this.user.general_profile.mobile,
      email: this.user.general_profile.email,
      address: this.user.address_profile.address1,
      province: this.user.address_profile.province,
      sub_district: this.user.address_profile.sub_district,
      district: this.user.address_profile.district,
      road: this.user.address_profile.road,
      postcode: this.user.address_profile.postcode,
    };
  },
};
</script>
<style lang="scss" scoped>
.btn-box {
  width: 285px;
}
.register-color {
  color: #235d3a;
  background: linear-gradient(
    93.93deg,
    rgba(35, 93, 58, 0.1) 5.04%,
    rgba(115, 192, 136, 0.1) 100%
  );
  border-bottom: 1px solid #73c088;
}
</style>
