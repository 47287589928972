<template>
  <div class="register-title primary--text pa-5">
    <div class="d-flex align-center justify-space-between">
      <div
        v-if="onConsent"
        class="back-btn mr-3 rounded-circle d-flex align-center justify-center w-40 h-40"
        @click="back()"
      >
        <img :src="arrowIcon" width="20" />
      </div>
      <div v-show="!onConsent" class="title">
        <div class="text-20 font-700 line-h-1">กรอกข้อมูลลงทะเบียน</div>
        <div class="text-18 font-400">เพื่อเป็นสมาชิกกับเรา</div>
      </div>
      <div v-show="onConsent" class="title">
        <div class="text-20 font-700 line-h-1">เงื่อนไขและข้อตกลง</div>
        <div class="text-20 font-700">การใช้บัตรสมาชิก</div>
      </div>
      <div class="register-step">
        <div class="circle-group primary--bg relative">
          <div class="step-background absolute">
            <div class="step-group absolute d-flex align-center justify-center">
              <span class="font-400 text-28">{{ onSteps }}</span>
              <span class="text-20">/{{ isRegistered ? 2 : 3 }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-spacer></v-spacer>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "registerTitle",
  data() {
    return {
      arrowIcon: require("@/assets/icon/arrowleft.png"),
    };
  },
  props: {
    isRegistered: {
      type: Boolean,
      default: false,
    },
    onSteps: {
      type: Number,
      default: 1,
    },
    onConsent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("Layout", ["onDomain"]),
  },

  methods: {
    back() {
      this.$emit("closeTerm");
    },
  },
};
</script>
<style lang="scss" scoped>
.register-title {
  background: linear-gradient(
    180deg,
    rgba(231 231 231) 50%,
    rgba(115, 192, 136, 0.1) 100%
  );
  border-bottom: 1px solid var(--v-primaryLight-base);
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
}
.back-btn {
  background-color: white;
  width: 40px;
  height: 40px;
}
.circle-group {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.step-background {
  background-color: white;
  width: 60px;
  height: 60px;
  border-radius: inherit;
}
.step-group {
  background: linear-gradient(
    93.93deg,
    rgba(35, 93, 58, 0.1) 5.04%,
    rgba(115, 192, 136, 0.1) 100%
  );
  width: 60px;
  height: 60px;
  border-radius: inherit;
}
</style>
