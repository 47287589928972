<template>
  <div class="register-form">
    <v-container class="register-form-group mt-5 mb-5 px-8 text-color">
      <v-row class="register-form-title primary-gradient--bg py-1 px-2">
        <v-col class="text-20 font-700">ส่วนที่ 1 : ข้อมูลทั่วไป</v-col>
      </v-row>
      <v-row class="profile-form font-500 text-14 px-2">
        <v-col class="col-12">
          <div>ชื่อจริง <span class="error--text">*</span></div>
          <ValidationProvider
            name="firstName"
            :rules="{ required: true, regex: /^[a-zA-Zก-๙]*$/ }"
            v-slot="{ errors }"
          >
            <v-text-field
              class="mt-3 text-14"
              v-model="profile.firstName"
              outlined
              rounded
              dense
              required
              hide-details="auto"
              placeholder="โปรดกรอกชื่อจริง"
            ></v-text-field>
            <span class="error--text">{{ errors[0] }}</span>
          </ValidationProvider>
        </v-col>
        <v-col class="col-12">
          นามสกุล <span class="error--text">*</span>
          <ValidationProvider
            name="lastName"
            :rules="{ required: true, regex: /^[a-zA-Zก-๙]*$/ }"
            v-slot="{ errors }"
          >
            <v-text-field
              class="mt-3 text-14"
              v-model="profile.lastName"
              outlined
              rounded
              dense
              required
              hide-details="auto"
              placeholder="โปรดกรอกชื่อนามสกุล"
            ></v-text-field>
            <span class="error--text">{{ errors[0] }}</span>
          </ValidationProvider>
        </v-col>
        <v-col class="col-12">
          เพศ <span class="error--text">*</span>
          <ValidationProvider
            name="gender"
            rules="required"
            v-slot="{ errors }"
          >
            <v-select
              class="mt-3 text-14"
              v-model="profile.gender"
              :items="genderOption"
              item-value="value"
              item-text="name"
              placeholder="กรุณาเลือกเพศ"
              data-vv-name="select"
              required
              rounded
              outlined
              dense
              hide-details="auto"
            ></v-select>
            <span class="error--text">{{ errors[0] }}</span>
          </ValidationProvider>
        </v-col>
        <v-col class="col-12">
          วัน / เดือน / ปีเกิด <span class="error--text">*</span>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <ValidationProvider
                name="datebirthday"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="getDateFormat"
                  placeholder="ระบุวันเกิด"
                  readonly
                  outlined
                  rounded
                  dense
                  class="text-12"
                  hide-details="auto"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
                <span class="error--text">{{ errors[0] }}</span>
              </ValidationProvider>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              :active-picker.sync="activePicker"
              :max="maxAge"
              :min="minAge"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="col-12">
          เบอร์โทรศัพท์ <span class="error--text">*</span>
          <ValidationProvider
            name="mobile"
            :rules="{ required: true, regex: /^[0-9]*$/, max: 10 }"
            v-slot="{ errors }"
          >
            <v-text-field
              class="mt-3 text-14"
              v-model="profile.phoneNumber"
              outlined
              rounded
              dense
              required
              hide-details="auto"
              placeholder="โปรดกรอกเบอร์โทรศัพท์ Ex. 0891231234"
            ></v-text-field>
            <span class="error--text">{{ errors[0] }}</span>
          </ValidationProvider>
        </v-col>
        <v-col class="col-12">
          อีเมล
          <ValidationProvider name="email" rules="email" v-slot="{ errors }">
            <v-text-field
              class="mt-3 text-14"
              v-model="profile.email"
              outlined
              rounded
              dense
              hide-details="auto"
              placeholder="กรุณากรอกอีเมล Ex. abc@gmail.com"
            ></v-text-field>
            <span class="error--text">{{ errors[0] }}</span>
          </ValidationProvider>
        </v-col>
        <v-col class="col-12">
          ที่อยู่ปัจจุบัน <span class="error--text">*</span>
          <ValidationProvider
            name="address"
            rules="required"
            v-slot="{ errors }"
          >
            <v-text-field
              class="mt-3 text-14"
              v-model="profile.address"
              outlined
              rounded
              dense
              required
              hide-details="auto"
              placeholder="โปรดกรอกที่อยู่ปัจจุบัน"
            ></v-text-field>
            <span class="error--text">{{ errors[0] }}</span>
          </ValidationProvider>
        </v-col>
        <v-col class="col-12">
          จังหวัด <span class="error--text">*</span>
          <ValidationProvider
            name="province"
            rules="required"
            v-slot="{ errors }"
          >
            <!-- <v-text-field
            class="mt-3 text-14"
            v-model="profile.province"
            outlined
            rounded
            dense
            required
            hide-details="auto"
            placeholder="โปรดกรอกจังหวัด"
          ></v-text-field> -->
            <addressinput-province
              placeholder="โปรดกรอกจังหวัด"
              v-model="profile.province"
              label=""
            />
            <span class="error--text">{{ errors[0] }}</span>
          </ValidationProvider>
        </v-col>
        <v-col class="col-12">
          เขต / อำเภอ <span class="error--text">*</span>
          <ValidationProvider
            name="district"
            rules="required"
            v-slot="{ errors }"
          >
            <!-- <v-text-field
            class="mt-3 text-14"
            v-model="profile.district"
            outlined
            rounded
            dense
            required
            hide-details="auto"
            placeholder="โปรดกรอกเขต / อำเภอ"
          ></v-text-field> -->
            <addressinput-district
              class="mt-1"
              v-model="profile.district"
              placeholder="โปรดกรอกเขต / อำเภอ"
              label=""
            />
            <span class="error--text">{{ errors[0] }}</span>
          </ValidationProvider>
        </v-col>
        <v-col class="col-12">
          แขวง / ตำบล <span class="error--text">*</span>
          <ValidationProvider
            name="sub_district"
            rules="required"
            v-slot="{ errors }"
          >
            <!-- <v-text-field
            class="mt-3 text-14"
            v-model="profile.sub_district"
            outlined
            rounded
            dense
            required
            hide-details="auto"
            placeholder="โปรดกรอกแขวง / ตำบล"
          ></v-text-field> -->
            <addressinput-subdistrict
              class="mt-1"
              v-model="profile.sub_district"
              placeholder="โปรดกรอกแขวง / ตำบล"
              label=""
            />
            <span class="error--text">{{ errors[0] }}</span>
          </ValidationProvider>
        </v-col>
        <v-col class="col-12">
          ถนน <span class="error--text">*</span>
          <ValidationProvider name="road" rules="required" v-slot="{ errors }">
            <v-text-field
              class="mt-3 text-14"
              v-model="profile.road"
              outlined
              rounded
              dense
              required
              hide-details="auto"
              placeholder="โปรดกรอกถนน"
            ></v-text-field>
            <span class="error--text">{{ errors[0] }}</span>
          </ValidationProvider>
        </v-col>
        <v-col class="col-12">
          รหัสไปรษณีย์ <span class="error--text">*</span>
          <ValidationProvider
            name="postcode"
            :rules="{ required: true, regex: /^[0-9]*$/, max: 5, min: 5 }"
            v-slot="{ errors }"
          >
            <!-- <v-text-field
            class="mt-3 text-14"
            v-model="profile.postcode"
            outlined
            rounded
            dense
            required
            hide-details="auto"
            placeholder="โปรดกรอกรหัสไปรษณีย์"
          ></v-text-field> -->
            <addressinput-zipcode
              class="mt-1"
              v-model="profile.postcode"
              placeholder="โปรดกรอกรหัสไปรษณีย์"
              label=""
            />
            <span class="error--text">{{ errors[0] }}</span>
          </ValidationProvider>
        </v-col>
        <v-col class="col-12">
          <div class="text-center mb-5">
            <v-btn
              class="white--text text-16 py-4 primary--bg"
              type="submit"
              rounded
              @click="submit()"
            >
              ถัดไป<v-icon right white> mdi-arrow-right </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "registerform",
  data(vm) {
    return {
      dateFormatted: vm.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
      activePicker: null,
      menu: false,
      date: null,
      genderOption: [
        { value: "male", name: "ชาย" },
        { value: "female", name: "หญิง" },
      ],
      profile: this.profiles,
      onStep: 0,
    };
  },
  props: {
    profiles: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    maxAge() {
      const date = new Date();
      date.setFullYear(date.getFullYear() - 0);
      return `${new Date(date).toISOString().split("-")[0]}-12-31`;
    },
    minAge() {
      const date = new Date();
      date.setFullYear(date.getFullYear() - 90);
      return `${new Date(date).toISOString().split("-")[0]}-0-0`;
    },
    getDateFormat() {
      if (this.computedDateFormatted) {
        if (this.computedDateFormatted.search("-") !== -1) {
          const date = this.computedDateFormatted.split("-");
          return `${date[2]}-${date[1]}-${date[0]}`;
        } else {
          return this.computedDateFormatted;
        }
      } else {
        return "";
      }
    },
    computedDateFormatted() {
      if (this.profile.birth_date) {
        return this.profile.birth_date;
      } else {
        return this.formatDate(this.date);
      }
    },
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
      this.profile.birth_date = this.date;
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    submit() {
      this.$emit("submit", this.profile);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.register-form-title {
  color: #fff;
  border-radius: 20px 20px 0 0;
}

.profile-form {
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
  background: #fff;
}

.text-color {
  color: #757575;
}
</style>
