<template>
  <v-row>
    <v-dialog v-model="active" v-if="status" persistent max-width="350">
      <v-card class="card px-4 pt-7 pb-10">
        <div v-if="option[status].showIcon" class="text-center mb-2">
          <img :src="errIcon" width="70" class="error-icon" alt="" />
        </div>
        <div class="message text-center text-22 font-700">
          {{ option[status].title }}
        </div>
        <div class="message text-center mb-5 text-16 font-500">
          {{ option[status].description }}
        </div>
        <div class="action d-flex justify-center">
          <v-btn
            text
            class="submit-btn pa-5 rounded-pill text-16 font-500"
            @click="handleClick"
            >{{ option[status].button }}</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "error-modal",
  data() {
    return {
      option: {
        notFoundCode: {
          title: "ลงทะเบียนไม่สำเร็จ",
          description: "กรุณาตรวจสอบข้อมูล หรือติดต่อเจ้าหน้าที่",
          showIcon: true,
          button: "ตกลง",
        },
        registerUnsccess: {
          title: "ไม่พบรหัสลงทะเบียน",
          description: "ระบบไม่พบขข้อมูลการลงทะเบียนของท่าน",
          showIcon: true,
          button: "ตกลง",
        },
        collectCouponFail: {
          title: "เก็บคูปองไม่สำเร็จ",
          description: "ระบบไม่สามารถเก็บคูปองนี้ได้",
          showIcon: true,
          button: "ตกลง",
        },
        renewMember: {
          title: "ต่ออายุบัตรสมาชิกไม่สำเร็จ",
          description: "กรุณาติดต่อเจ้าหน้าที่",
          showIcon: true,
          button: "ตกลง",
        },
        notfoundMobile: {
          title: "ไม่พบเบอร์โทรศัพท์",
          description: "กรุณาตรวจสอบข้อมูลอีกครั้ง",
          showIcon: true,
          button: "กลับไปตรวจสอบ",
        },
        baby: {
          title: "ไม่สามารถเพิ่มข้อมูลบุตรของท่านในระบบ",
          description: "สมาชิกได้กรุณาตรวจสอบข้อมูลอีกครั้ง",
          showIcon: true,
          button: "กลับไปตรวจสอบ",
        },
        record: {
          title: "บันทึกข้อมูลไม่สำเร็จ",
          description: "กรุณาตรวจสอบข้อมูลอีกครั้ง",
          showIcon: true,
          button: "กลับไปตรวจสอบ",
        },
        couponCollected: {
          title: "คูปองนี้ถูกเก็บครบแล้ว",
          description: "จำนวนคูปองนี้ถูกผู้ใช้งานอื่นเก็บครบหมดแล้ว",
          showIcon: true,
          button: "ตกลง",
        },
        cantUseCoupon: {
          title: "ใช้คูปองไม่สำเร็จ",
          description: "คูปองนี้อาจถูกใช้งานแล้ว หรือหมดอายุ",
          showIcon: true,
          button: "ตกลง",
        },
        couponAlreadyUsed: {
          title: "คูปองถูกใช้สิทธิ์ครบแล้ว",
          description: "",
          showIcon: true,
          button: "ตกลง",
        },
        couponExpire: {
          title: "คูปองหมดอายุ",
          description: "",
          showIcon: true,
          button: "ตกลง",
        },
      },

      errIcon: require("@/assets/icon/error.svg"),
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleClick() {
        this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
  border-radius: 20px;
}
.message {
  color: #931111;
}
.submit-btn {
  background: #931111;
  color: white;
}
.action {
  column-gap: 1rem;
}

.close {
  position: absolute;
  left: 290px;
  top: 15px;
}
</style>
