<template>
  <v-row justify="center">
    <v-dialog v-model="active" persistent max-width="350">
      <v-card class="card">
        <v-card-title
          class="bg-color primary-gradient--bg d-flex justify-space-around pa-3"
        >
          <v-spacer></v-spacer>
          <v-toolbar-title class="text-20 text-center"
            >ลงทะเบียนแบบมีรหัส</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn class="bg-icon" icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <div class="d-flex justify-center">
          <div>
            <div
              v-if="isInvalid"
              class="message primary--text text-center text-16 mt-5 font-500"
            >
              กรุณากรอกรหัสลงทะเบียน <br />(รับรหัสจากเจ้าหน้าที่ รพ.)
            </div>
            <div
              v-else-if="onDomain === 'be'"
              class="message primary--text text-center text-16 mt-5 font-500"
            >
              กรุณากรอกเบอร์ของท่าน
            </div>
            <div
              v-else
              class="message primary--text text-center text-16 mt-5 font-500"
            >
              กรุณากรอกเบอร์โทรศัพท์<br />ที่ซื้อแพคเกจเพื่อลงทะเบียน
            </div>
            <div class="mt-4 ma-auto">
              <v-text-field
                style="width: 275px"
                class="text-14"
                :placeholder="
                  onDomain === 'be'
                    ? 'เบอร์หรือรหัสลงทะเบียน'
                    : 'กรุณากรอกรหัสที่ท่านได้รับมาที่นี่...'
                "
                outlined
                rounded
                hide-details
                dense
                v-model="code"
              >
              </v-text-field>
            </div>
            <div
              v-show="isInvalid"
              class="text-center mt-2 isInvalid-code error--text"
            >
              ไม่พบรหัส
            </div>
            <div class="mt-5 d-flex justify-center mb-9">
              <v-btn
                text
                class="register-btn primary--bg btn text-16 py-5"
                @click="submit"
                >{{ isInvalid ? "ยืนยัน" : "ลงทะเบียน" }}</v-btn
              >
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: "registerwithcodemodal",
  data() {
    return {
      closeicon: require("@/assets/icon/closeicon.png"),
      code: this.registerCode,
    };
  },
  props: {
    registerCode: {
      type: String,
      default: "",
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    onDomain: {
      type: String,
      default: "",
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submit() {
      this.$emit("submit", this.code);
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
  border-radius: 20px;
}
.message {
  color: #235d3a;
}
.bg-color {
  color: #ffffff;
}

.register-btn {
  color: white;
}

.bg-icon {
  background: #fff;
}
</style>
