<template>
  <v-row justify="center">
    <v-dialog v-model="active" persistent max-width="350">
      <v-card class="card primary--text pa-7 text-center">
        <div class="success-icon">
          <img :src="successIcon" width="70px" />
        </div>
        <div class="message-title text-22 font-700">{{ message.title }}</div>
        <div
          class="description text-16 font-500"
          style="overflow-wrap: break-word"
          v-html="message.description"
        ></div>
        <v-btn class="btn mt-5 submit-btn primary--bg font-500" @click="handleClick"
          >ตกลง</v-btn
        >
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "register-success",
  data() {
    return {
      successIcon: require("@/assets/icon/success.png"),
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    message: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    handleClick() {
      this.$emit("submit");
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
  border-radius: 20px;
}
.submit-btn {
  color: white;
}
</style>
