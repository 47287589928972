<template>
  <div class="register-form">
    <v-container class="register-form-group mt-5 mb-5 px-8 text-color">
      <v-row class="register-form-title primary-gradient--bg py-1 px-2">
        <v-col class="text-20 font-700">ส่วนที่ 2 : ข้อมูลโรงพยาบาล</v-col>
      </v-row>
      <v-row class="profile-form text-14 font-500 px-2 d-flex">
        <div v-if="onDomain === 'be'">
          <v-col class="col-12">
            ภายใน 5 ปี ที่ผ่านมา คุณเคยเข้ารับการรักษา หรือ เข้ารับบริการที่ รพ.
            พญาไท 3 หรือไม่ ? <span class="error--text">*</span>
          </v-col>
          <v-col class="col-12 pa-0">
            <v-container class="" fluid>
              <v-radio-group
                v-model="term.isVisitPT"
                row
                hide-details="auto"
                class="py-0 my-0"
                mandatory
              >
                <v-radio
                  class="col-6 pa-0 ma-0"
                  label="เคย"
                  color="#73C088"
                  :value="true"
                  hide-details="auto"
                ></v-radio>
                <v-radio
                  class="col-6 pa-0 ma-0"
                  label="ไม่เคย"
                  color="#73C088"
                  :value="false"
                  hide-details="auto"
                ></v-radio>
              </v-radio-group>
            </v-container>
          </v-col>
          <v-col>
            <hr />
          </v-col>
        </div>
        <v-col
          :class="{ 'mt-5': onDomain !== 'be' }"
          class="col-12 text-20 font-600 pt-0 primary--text"
        >
          ข้อกำหนดและเงื่อนไข
        </v-col>
        <v-col class="col-12 pt-0 font-400">
          <v-checkbox
            hide-details
            class="mt-0 primary--text"
            v-model="term.pdpa"
            :value="term.pdpa"
          >
            <template v-slot:label>
              <span class="text-14">ยอมรับข้อกำหนดข้อมูลส่วนตัว PDPA</span>
            </template>
          </v-checkbox>
          <v-btn class="ml-4 mt-0 pt-0" text @click="readDetial('pdpa')">
            <span class="font-400 primary--text text-decoration-underline text-12"
              >อ่านรายละเอียด</span
            >
          </v-btn>
        </v-col>
        <v-col class="col-12 pt-0 font-400">
          <v-checkbox hide-details class="mt-0 primary--text" v-model="term.marketing" :value="term.marketing">
            <template v-slot:label>
              <span class="text-14"
                >ยอมรับข้อตกลงและเงื่อนไขเกี่ยวกับการตลาด
              </span>
            </template>
          </v-checkbox>
          <v-btn class="ml-4 mt-0" text @click="readDetial('marketing')">
            <span class="font-400 primary--text text-decoration-underline text-12"
              >อ่านรายละเอียด</span
            >
          </v-btn>
        </v-col>
        <v-col class="col-12 pt-0 font-400">
          <v-checkbox hide-details class="mt-0 primary--text" v-model="term[onDomain]">
            <template v-slot:label>
              <span class="text-14"
                >ยอมรับข้อกำหนดเงื่อนไขการใช้งานบัตรสมาชิก {{ cardType }}</span
              >
            </template>
          </v-checkbox>
          <v-btn class="ml-4 mt-0" text @click="readDetial(onDomain)">
            <span class="font-400 primary--text text-decoration-underline text-12"
              >อ่านรายละเอียด</span
            >
          </v-btn>
        </v-col>
        <v-col class="col-12">
          <div class="text-center">
            <v-btn
              class="white--text text-16 py-5 px-8 font-500 primary--bg"
              type="submit"
              rounded
              @click="submit"
            >
              ยืนยันการลงทะเบียน
            </v-btn>
          </div>
        </v-col>
        <v-col>
          <div class="text-center mb-5" @click="backStep">
            <v-icon left small> mdi-arrow-left </v-icon>
            <span class="text-decoration-underline">ย้อนกลับ</span>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      term: this.terms,
    };
  },
  props: {
    terms: {
      type: Object,
      default: () => ({}),
    },
    onDomain: {
      type: String,
      default: "",
    },
  },
  computed: {
    cardType() {
      if (this.onDomain === "be") {
        return "Be Phyathai 3 Family";
      } else if (this.onDomain === "prestige") {
        return "Long Term Prestige";
      } else if (this.onDomain === "all-you") {
        return "All You Can Check";
      } else if (this.onDomain === "mom-baby") {
        return "Mommy & Baby Club";
      } else {
        return "";
      }
    },
  },
  methods: {
    readDetial(value){
      this.$emit("readDetail", value);
    },
    backStep() {
      this.$emit("back");
    },
    submit() {
      this.$emit("submit", this.term);
    },
  },
};
</script>
<style lang="scss" scoped>
.register-form-title {
  color: #fff;
  border-radius: 20px 20px 0 0;
}

.profile-form {
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
  background: #fff;
}

.text-color {
  color: #757575;
}

.btn-suscess {
  width: 200px;
}
.btn-bg {
  background: white;
}
</style>
